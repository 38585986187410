export const TRANSLATIONS_ZH = {
    userTotalStorage: "總存儲量",
    //Right Nav
    dashboard: "儀表板",
    sendProductToSKUDrop: "將產品發送到 SKUdrop",
    sendProductToAmazon: "將產品發送到 Amazon",
    addProduct: "添加產品或品牌",
    createOutboundShipments: "創建出站貨物",
    requestForFreightQuote: "申请运费报价",
    //Main Totals
    totalUnitsStored: "存儲的總單位",
    totalCartonsStored: "儲存的紙箱總數",
    totalPalletsStored: "儲存的托盤總數",
    cubicMetersStored: "儲存的立方米總數",
    cubicFeetStored: "立方英尺存儲",
    //Main Incoming totals
    unitsIncoming: "入库單位數",
    cartonsIncoming: "入库紙箱數",
    palletsIncoming: "入库托盤數",
    cubicMetersIncoming: "立方米進貨",
    cubicFeetIncoming: "立方英尺進貨",
    // in Prep
    inPrep: "在準備中",
    // Switch
    cubicMeters: "立方米",
    cubicFeet: "立方英尺",
    // Table Names
    storedProduct: "存儲產品",
    productsPrepped: "正在準備的產品",
    incomingProducts: "入库產品",
    productsRequiringActions: "需要操作的產品",
    uploadedProducts: "上傳的產品",
    seeAll: "查看全部",
    allStoredProduct: "所有存儲的產品",
    allProductsPrepped: "所有產品正在準備中",
    allIncomingProducts: "所有進貨產品",
    allProductsRequiringActions: "所有需要操作的產品",
    allUploadedProducts: "所有上傳的產品",
    searchFor: "搜索",
    typeIn: "输入",
    typeInA: "输入一个",
    searchForNames: "搜索名字...",
    searchForSKU: "搜索 SKU...",
    searchForDate: "搜索日期...",
    close: "關閉",
    cancel: "取消",
    delete: "刪除",
    confirmationRequired: "需要確認",
    save: "保存",
    confirmDeletion: "確認刪除",
    addMultipleSKUsSeparatedByComma: "添加多个 SKU，以逗号分隔",
    typeSKUsToShipThenPressEnter: "输入要发货的 SKU，然后按 Enter...",
    typeSKUsThenPressEnter: "输入 SKU，然后按 Enter...",
    // Tables Header Title
    brandName: "品牌",
    productName: "產品名稱",
    sku: "SKU",
    unitsPerCarton: "每箱的單位数",
    cartonWeight: "紙箱重量",
    cartonDimension: "紙箱尺寸",
    numberOfUnits: "單位數量",
    numberOfCartons: "紙箱數量",
    numberOfPallets: "托盤數量",
    incomingId: "傳入 ID",
    action: "行動",
    orderNumber: "訂單號",
    amazonFBAID: "Amazon FBA ID",
    reason: "原因",
    listReason: "列出原因",
    productDetails: "產品詳情",
    masterCartonDimensions: "主紙箱尺寸",
    pleaseUpdateTheNumberOfCartons: "請更新每個托盤的紙箱數量",
    masterCartonOnPallet: "托盤上的主紙箱",
    skog: "SKOG",
    // Send product to SKUdrop
    sendProductToSKUDropTitle: "選擇要發送到 SKUdrop 的紙箱數量",
    noPrepareToShipProduct: "抱歉，您不必準備在 SKUdrop 中運送產品！",
    masterCartons: "主紙箱",
    unitPrice: "單價",
    commercialInvoice: "商業發票",
    update: "更新",
    upload: "上傳",
    submit: "提交",
    estimateDateTitle: "預計貨物到達日期",
    sendProductToSKUDropModalTitle: "SKUdrop 團隊已準備好等待您的貨物到達。",
    sendProductToSKUDropModalDescription: "我們已發送一封電子郵件，其中包含我們的倉庫地址詳細信息，以便將紙箱交付給 SKUDrop。",
    factoryNameAlreadyTaken: "厂名已被占用",
    useExistingSavedSupplier: "使用已保存的现有供应商",
    orAddNewFactoryDetails: "或添加新的工厂详细信息",
    selectFactory: "选择工厂",
    contactPersonName: "联系人姓名",
    contactPersonPhoneNumber: "联系人电话号码",
    nicknameForThisProductsFactory: "该产品工厂的昵称",
    // Bulk Commercial Invoice
    bulkUpload: "批量上传",
    bulkCommercialInvoiceUpload: "批量商业发票上传",
    uploadCommercialInvoiceAndAssignMultipleSKUsToIt: "上传商业发票并为其分配多个 SKU。",
    uploadInvoice: "上传发票",
    uploadTheCommercialInvoiceToUse: "上传商业发票以供使用。",
    assignMultipleSKUsToUseTheUploadedInvoice: "分配多个 SKU 以使用上传的发票。",
    saveInvoiceForTheseSKUs: "保存这些 SKU 的发票",
    // Bulk SKU Inbound
    inboundSKUsInBulk: "散装入库 SKU",
    bulkSkuInbounding: "批量SKU入库",
    followTheStepsBelowToBulkInboundSKUsToTheSKUdropWarehouse: "请按照以下步骤将入站 SKU 批量发送到 SKUdrop 仓库。",
    downloadTheBulkInboundTemplate: "下载批量入站模板。",
    fillInTheTemplateWithTheDetailsOfYourThatYouWantToSendToSKUdrop: "在模板中填写您想要发送到 SKUdrop 的 SKU 的详细信息。",
    // Send Product to Amazon
    noSendProductToAmazon: "抱歉，您沒有存儲在 SKUdrop 中的產品！",
    sendProductToAmazonStepOneTitle: "步骤 1 - 選擇運輸方式和紙箱數量",
    cartonsStored: "存放紙箱",
    destination: "目的地",
    selectNumberOfCartons: "選擇紙箱數量",
    units: "單位",
    comingSoon: "快來了",
    createShipmentPlanButtonTitle: "在賣方中心創建運輸計劃",
    shipmentID: "貨件編號",
    shipTo: "運送到",
    unitsExpected: "預計單位",
    calculateShippingEstimateButtonTitle: "運費估算",
    sendProductToAmazonStepTwoTitle: "步骤 {{step}} - 选择运费选项",
    submitShippingOrderButtonTitle: "確認運輸計劃、估算並發送紙箱標籤",
    sendProductToAmazonModalTitle: "成功！ 您的運輸計劃和標籤已發送至 SKUdrop",
    sendProductToAmazonModalNote: "注意：如果您刪除或更改您的運輸計劃，您必須聯繫",
    asap: "盡快",
    goToDashboardButtonTitle: "轉到儀表板",
    consolidateShipments: "合并发货",
    displayShippingEstimate: "显示运费预估",
    reduceNumberOfShippingPlans: "选择此按钮可减少运输计划的数量",
    mostCostEffectivePlans: "最具成本效益的计划",
    leastCostEffectivePlans: "成本最低的有效计划",
    reducesShippingCostsBy: "降低运输成本",
    increasesShippingCostsBy: "运输成本增加",
    stepSelectDestinationAndShippingMethodOrEnterFbaIDs: "步骤 1 - 选择目的地和运输方式或输入 FBA ID 以创建货件",
    stepSelectShippingOptions: "步骤 1 - 选择运输选项",
    enterFbaIDsOrUploadAwdLabelsToCreateShipmentsFromSellerCentral: "输入 FBA ID 或上传 AWD 标签以从卖家中心创建货件",
    stepSelectDestinationAndShippingMethod: "步骤 1 - 选择目的地和运输方式",
    stepRetrievingFbaIDShippingInformation: "检索 FBA ID 运输信息",
    stepRetrievingAwdShippingInformation: "检索 AWD 运输信息",
    stepAddFreightForwarderDetails: "步骤 {{step}} - 添加货运代理详细信息",
    stepConfirmPrepFees: "步骤 {{step}} - 确认准备费用",
    stepSelectNumberOfCartons: "步骤 {{step}} - 选择纸箱数量",
    stepSelectNumberOfCartonsToBuildContainer: "步骤 2 - 选择构建集装箱的纸箱数量",
    stepUploadLabelToPrep: "步骤 3 - 将标签上传到 Prep",
    stepChooseContainerSize: "步骤 1 - 选择您的集装箱尺寸",
    confirmPrepFeesSubmitShipment: "确认准备费用并提交发货",
    clear: "清除",
    shipToAmazon: "运送至亚马逊",
    enterFbaIdHereThenPressENTER: "在此输入 FBA ID，然后按 ENTER",
    labelUploadedWillBeAppliedToAllCartons: "上传的标签将应用于货件中的所有纸箱",
    noLabelRequiredForPrep: "准备时无需标签",
    uploadShippingAddressLabels: "上传送货地址标签",
    selectFreightForwarderDetails: "选择货运代理详细信息",
    youCanSelectAndUpdateTheSavedFreightForwarderDetails: "您可以选择并更新已保存的货运代理详细信息",
    logisticsCompanyName: "物流公司名称",
    containerSize: "集装箱尺寸",
    remainingCbmAvailableToLoad: "剩余可装载 CBM",
    remainingWeightAvailableToLoad: "剩余可装载重量",
    ownFreightForwarder: "自有货运代理",
    pickCartons: "挑选纸箱",
    retrieveFromRacks: "从货架上取回",
    prepCartons: "准备纸箱",
    labelCartons: "标签纸箱",
    loadIntoContainer: "装入容器",
    loadYourSKUsIntoContainer: "将您的 SKU 装入容器中",
    loadCartons: "装载纸箱",
    loadCartonsForTransport: "装载纸箱进行运输",
    welcomeToYour: "欢迎来到您的",
    containerBuilder: "容器建造者",
    selectCartonQuantitiesToBuildYourContainerBasedOnTheRequiredCBM: "根据所需的 CBM 选择纸箱数量来构建集装箱。",
    watchHelpVideo: "观看帮助视频",
    stepChooseYourContainerSize: "步骤 1 - 选择您的容器尺寸",
    createFlatFile: "创建平面文件",
    flatFileCreated: "平面文件已创建",
    uploadFlatFileInSkuSelectionMethodInSellerCentralToCreateThisShipment: "在卖家中心的 SKU 选择方法中上传平面文件以创建此货件。",
    onceYourShipmentIsReadyInSellerCentralEnterYour: "一旦您的货件在卖家中心准备就绪，请输入您的",
    fbaIDsOnThisPageToSendThisShipmentToWarehouse: "此页面上的 FBA ID 用于将此货件发送到仓库。",
    dontShowThisAgain: "不要再显示这个",
    weWereUnableToReceiveYourOrderOrLabels: "我们无法收到您的订单或标签。 请上传您的 FBA 标签。",
    orderReceived: "收到订单",
    labelsReceived: "收到标签",
    labelUpload: "标签上传",
    clickHere: "点击这里",
    uploadLabels: "上传标签",
    shipWithSKUdrop: "使用 SKUdrop 发货",
    done: "完毕",
    aglHelpVideo: "AGL 帮助视频",
    uploadAwdLabels: "上传 AWD 标签",
    noCartonsToCreateOutboundShipment: "没有纸箱，无法进行外运。",
    // Own ff announcement modal
    ownFFAnnouncementModalTitle1: `引入自定义运输选项:\n使用您自己的货运代理`,
    ownFFAnnouncementModalTitle2: `利用在中国存储的优势,\n将您的纸箱直接运送到亚马逊`,
    ownFFAnnouncementModalTitle3: `利用 B2B\n全球货运的力量`,
    ownFFAnnouncementModalDesc1: "现在，您可以完全灵活地控制您的运输要求，以最好地满足您的需求。",
    ownFFAnnouncementModalDesc2: "运送至亚马逊并创建 LCL 或 FCL 货件。 使用 AGL 的运输选项可以消除库存安置费用。",
    ownFFAnnouncementModalDesc3: "轻松将您的纸箱运送到亚马逊以外的世界任何地方。",
    // Own ff AGF modal
    importantInformation: "重要信息",
    whenDoingAglShipments: "进行 AGL 运输时",
    whenCreatingAGLShipmentsInSellerCentral: "在卖家中心创建AGL发货时，需要设置一些非常重要的设置以确保发货顺利。",
    ownFFAglClickNextBelow: "点击下面的<strong>下一步</strong>，完成设置。",
    setting: "设置 {{value}}",
    originCityShouldBeSetToNingbo: "始发城市应设置为宁波。",
    valueAddedServicesShouldBeSetToSupplierDropsOff: "增值服务应设置为供应商还车。",
    SKUdropWillDoTheDropOffToThePort: "SKUdrop 将代表您将货物运送到港口。 需支付运费。",
    shipperShouldBeSetToYourSuppliersAddress: "发货人应设置为您的供应商地址，目的地联系人应设置为您的联系方式。",
    youMustDoOneAglShipmentPerSupplier: "您必须对每个供应商进行一次 AGL 装运，以简化出口文书工作。",
    selectDestinationContactForPrimaryContact: "选择主要联系人的目标联系人。",
    thisMeansThatAglWillIncludeYouInAll: "这意味着 AGL 会将您纳入该货件的所有通信中。",
    yourRoleWithAglShipments: "您在 AGL 货运方面的角色",
    youPlayAnImportantRoleInAglShipments: "您在 AGL 运输中发挥着重要作用。",
    inOrderForSKUdropToDeliverYourGoods: "为了让 SKUdrop 运送您的货物，我们需要您的供应商提供具体的文件来为您协调港口投递。",
    weMayNeedYouToChaseUpTheRelevant: "我们可能需要您办理相关文件，以便我们将您的货物发送到港口。",
    toLearnMoreAboutOurPortDeliveryRates: "要进一步了解我们的港口交货费率，并查看我们需要您的供应商提供的交货文件示例，请单击下面的下载按钮。",
    // Add products or brand
    importFromAmazonButtonTitle: "从AMAZON导入產品 ",
    addManualyButtonTitle: "手動添加產品詳細信息",
    addProductFromAmazonTitle: "從AMAZON賣家中心選擇要添加到 SKUdrop 的產品",
    hsCode: "HS編碼",
    name: "姓名",
    asin: "ASIN",
    selectAll: "全選",
    carton: "紙盒",
    cartons: "紙箱",
    pallet: "托盤",
    select: "選擇",
    updateOrImportProductFromAmazon: "从AMAZON更新或導入產品",
    importNewProducts: "导入新產品",
    updatedAllProducts: "更新所有產品",
    importingHasBeenStarted: "导入新產品已經開始！",
    updatingHasBeenStarted: "已開始更新亞馬遜產品",
    addProductFromAmazonButtonTitle: "將選定的產品添加到 SKUdrop",
    updateAmazonProductButtonTitle: "更新AMAZON產品",
    newBrandNamePlaceholder: "新品牌名稱或從下拉列表中選擇現有品牌名稱",
    manualyProductDetailsTitle: "SKUdrop 存儲產品詳細信息",
    addProductTooltipTitle: "如果您要添加變體，則可以從下一個屏幕上的現有產品複製主紙箱詳細信息。",
    next: "下一個",
    letMeRecheckButtonTitle: "讓我重新檢查",
    beforeWeSave: "在我們保存之前...",
    addVariationModalDescription: "您是否針對此變化調整了主紙箱的詳細信息，以便我們獲得正確的產品詳細信息？",
    masterCartonDetails: "主紙箱詳細信息",
    selectProductToCopy: "選擇產品以復制其紙箱詳細信息",
    inch: "英寸",
    cm: "厘米",
    height: "高度",
    width: "寬度",
    length: "長度",
    masterCartonWeight: "外箱重量",
    kg: "公斤",
    pounds: "磅",
    masterCartonPerPallet: "每個托盤的主紙箱",
    masterCartonPerPalletOptional: "每個托盤的主紙箱（可選）",
    basedOnAmazonPallet: "基於亞馬遜托盤閾值。",
    variationName: "變體名稱",
    variationASIN: "變化 ASIN",
    variationSKU: "變化 SKU",
    variationHSCode: "HS編碼",
    variationUnitPrice: "變動單價",
    adjustMasterCartonDetails: "在保存之前調整上面的主紙箱詳細信息以進行變化",
    addAnotherProductButtonTitle: "添加其他產品或品牌",
    saveAndCopyButtonTitle: "保存並複制以添加變化",
    saveAndGoButtonTitle: "保存並轉到儀表板",
    productCategory: "产品分类",
    productDocuments: "产品文件",
    waitingDocuments: "等待文件",
    documents: "文件",
    downloadDocumentsUpper: "下载文件",
    only3DecimalPlacesAreAllowed: "只允许保留 3 位小数",
    // Settings
    profile: "资料",
    billing: "計費",
    allUserBilling: "所有用戶計費",
    integrations: "集成",
    logOut: "登出",
    // Profile
    userDetails: "用戶詳情",
    lastName: "姓",
    email: "電子郵件",
    billingAdress: "帳單地址（可選）",
    line1: "1號線",
    postalCode: "郵政編碼",
    city: "城市",
    state: "狀態",
    country_code: "國家代碼",
    phone: "輸入您的手機",
    changePassword: "更改密碼",
    currentPassword: "當前密碼",
    newPassword: "新密碼",
    confirmPassword: "確認密碼",
    confirmSmal: "確認", //Added
    creditCard: "信用卡",
    userManagement: "用戶管理",
    addUser: "添加用戶",
    userEmail: "用戶電子郵件",
    userAlredyExist: "使用此電子郵件的用戶已存在",
    fullName: "全名",
    allUsers: "所有用戶",
    billingAccess: "计费访问",
    // Public API
    publicApiKeys: "公共 API 密钥",
    publicApiDescription: "在您的个人资料中生成您的公共 API 密钥，以将 SKUdrop 的功能与您现有的系统无缝集成。 解锁高效、自动化的交互，以增强业务运营。",
    keyName: "按键名称",
    lastUsed: "最后使用",
    extendExpiration: "延长有效期",
    extendExpirationDateBy: "将有效期延长",
    expirationDate: "截止日期",
    generateApiKey: "生成公共 API 密钥",
    createApiKey: "创建 API 密钥",
    apiKeyCreated: "API 密钥已创建",
    unlimited: "无限",
    invalidName: "请正确填写姓名",
    extend: "延长",
    copy: "复制",
    copied: "已复制",
    yourAPIKeyIsDisplayedOnlyOnce: "出于安全原因，您的 API 密钥仅显示一次。 请立即复制并安全保存，以后将无法找回",
    day: "天",
    days: "天",
    weeks: "周",
    month: "月",
    // Billing
    noBillingData: "您沒有帳單數據。",
    invoiceDate: "發票日期",
    status: "狀態",
    total: "全部的",
    openDownload: "發票",
    currentSkudropPricingTier: "您当前的 SKUdrop 定价等级和积分余额",
    cartonPreparationFee: "纸箱准备费",
    dailyCBMStorageRate: "煤层气日储存量",
    shippingCreditsBalance: "运输积分余额",
    cbmStorageRange: "CBM 存储范围",
    shippingCreditsUsed: "使用的运输积分",
    currentShippingCreditBalance: "您当前的 SKUdrop 定价等级和运输积分余额",
    // Payment terms
    paymentsDue: "到期付款",
    paymentsOverdue: "逾期付款",
    amountPayable: "应付金额",
    dueDate: "到期日",
    paymentMethod: "付款方式",
    // Past Shipments
    shippedToAmazon: "運送至Amazon",
    shippedToSKUDrop: "運送至SKUDrop",
    noShippmentsToAmazon: "您尚未向 AMAZON 運送任何紙箱。",
    noShippmentsToSKUDrop: "您尚未向 SKUdrop 運送任何紙箱。",
    orderId: "訂單編號",
    shipDate: "發貨日期",
    sentTo: "寄去",
    track: "追踪",
    cost: "成本",
    allShippedToAmazon: "全部運往Amazon的產品",
    allShippedToSKUDrop: "全部運往SKUDrop的產品",
    recivedDate: "收到的日期",
    // Integration
    integrationName: "集成名稱",
    authorizationDate: "授權日期",
    loginWithAmazon: "登錄 Amazon",
    noIntegrations: "没有集成。",
    // ADMIN WAREHOUSE
    searchPastOrders: "搜索過去的訂單",
    warehouseDashboard: "倉庫儀表板",
    chargingRates: "收費率",
    addNewWarehouse: "添加新倉庫",
    tenantList: "租戶名單",
    // Warehouse Dashboard
    orderToPrep: "準備訂單",
    orderToShip: "要發貨的訂單",
    incomingCartons: "進貨紙箱",
    productList: "储存产品",
    trackingIDEntry: "跟踪 ID 條目",
    tariffManagement: "關稅管理",
    tariffManagementTitle: "管理關稅和稅收",
    allOrderToPrep: "所有要準備的訂單",
    allOrderToShip: "所有訂單發貨",
    allIncomingCartons: "所有進貨紙箱",
    allProductList: "入库产品清单",
    allTrackingIDEntry: "所有跟踪 ID 條目",
    allTariffManagement: "所有關稅",
    noOrderToPrep: "沒有要準備的訂單。",
    noOrderToShip: "沒有要發貨的訂單。",
    noIncomingCartons: "沒有進貨紙箱。",
    noProductList: "沒有存儲產品。",
    noTrackingIDEntry: "沒有需要輸入跟踪 ID 的貨件。",
    noTariffManagement: "無需支付關稅。",
    waiting: "等待",
    fbaID: "FBA ID",
    timeReceived: "收到時間",
    files: "文件",
    completed: "完成",
    yes: "是的",
    no: "不",
    haveYouAddedFBALabels: "您是否添加了 FBA 標籤",
    howManyCartonsAreAvailable: "有多少紙箱可用？",
    adviceCustomer: "建議客戶",
    fileDownloads: "文件下載",
    download: "下載",
    fbaShippingLabel: "FBA 發貨標籤",
    shippingTemplate: "運輸模板",
    shippingToFBATemplate: "運送到 FBA 模板",
    pickedUp: "已接收",
    loaded: "已加載",
    finalWeight: "最終重量",
    cartonPerPallet: "每個托盤的紙箱",
    estDeliveryTime: "美東時間。 送貨",
    haveYouReceivedCartons: "確認收到紙箱",
    weight: "重量",
    productDetailInformation: "產品詳細信息",
    warehouseReceivedInformation: "倉庫收貨信息",
    downloadAll: "全部下載",
    dateShipped: "發貨日期",
    addTrackingID: "添加跟踪 ID",
    trackingID: "跟踪 ID",
    confirmTrackingID: "確認追踪 ID",
    enterTrackingIDBelow: "在下方輸入跟踪 ID",
    editTrackingIDsBelow: "编辑下面的跟踪 ID",
    editTrackingID: "编辑追踪 ID",
    enterTrackingID: "輸入跟踪 ID",
    invalidTrackingID: "跟踪 ID 無效",
    confirm: "確認",
    charge: "收費",
    chargeTariff: "收費关税",
    youAreAboutToCharge: "你要被收費了",
    forImportTariffs: "應付進口關稅和稅款",
    chargeCardOnFile: "檔案中的充值卡",
    chargeTariffAmountFor: "收取關稅金額",
    totalAmountPayable: "應付總額",
    haveYouDoubleChecked: "您是否對上述 FBA ID 的關稅金額進行了雙重檢查？",
    tryAgainUpper: "再试一次",
    uploadTariffFile: "上传关税文件",
    packingListFile: "包裝清單文件",
    tariffFile: "关税文件",
    warehouseLabels: "仓库标签",
    totals: "总计",
    downloadAllData: "下载所有数据",
    orderHaveZeroChargeAmount: "该订单的关税金额为 $0.00，您确定要继续吗？",
    ordersHaveZeroChargeAmount: "这些订单的关税金额为 $0.00，您确定要继续吗？",
    finalCBM: "最終 CBM",
    confirmFinalWeight: "確認最終重量",
    confirmFinalCBM: "確認最終 CBM",
    confirmFinalWeightAndCbm: "確認最終重量和 CBM",
    maximumDecimalPlaces: "最多允许 {{value}} 位小数",
    uploadProof: "上傳證明",
    pickUpTime: "取貨時間",
    chooseTime: "選擇時間",
    selectPickUpTime: "選擇取貨時間",
    uploadProofForOrder: "上傳訂單的證明",
    uploadedProofForOrder: "已上传订单证明",
    alreadyUploadedProof: "已上傳的證明",
    newUploadedProof: "新上傳的證明",
    uploadedProof: "上传的证明",
    view: "看法",
    moreDetails: "更多细节",
    country: "国家",
    orderDetails: "订单详细信息",
    success: "成功",
    error: "错误",
    processing: "加工",
    skudropOrders: "SKUdrop 订单",
    ffOrders: "FF 订单",
    fileDownloadsCapitalize: "文件下载",
    shipmentLabels: "货件标签",
    shippingLabelsCapitalize: "运输标签",
    alreadyUploadedProofsWillBeDeleted: "已经上传的证据将被删除",
    hasThisShipmentBeenPickedUp: "这批货已经被提走了吗？",
    haveTheseShipmentsBeenPickedUp: "这些货物已经被提走了吗？",
    hasThisOrderBeenPrepared: "这个订单准备好了吗？",
    orderTemplate: "订单模板",
    prepCostCapitalize: "准备费用",
    relatedSku: "相关 SKU",
    bulkPickedUp: "散装自提",
    bulkLoaded: "散装装载",
    exampleShort: "例如。",
    supplierDetails: "供应商详细信息",
    completedBulkActions: "已完成的批量行动",
    enterPriceHere: "在此输入价格",
    // Orders to ship: Bulk management
    bulkActions: "批量操作",
    selectBulkActionBellow: "选择下面的批量操作",
    selectUpToOrdersToBulkPickUpOrLoadToSaveTime: "选择最多 {{value}} 个订单进行批量提货或装载以节省时间。",
    typeOrderIdFbaIdThenPressEnter: "输入订单 ID、FBA ID，然后按 Enter 键",
    pickedUpBulkActions: "接手批量行动",
    loadedBulkActions: "装载大容量动作",
    allOrders: "所有订单",
    allOrdersWithValue: "所有 {{value}} 订单",
    noOrdersAvailable: "没有可用订单",
    verificationFile: "验证文件",
    // charging rates
    shippingRates: "運費",
    rates: "費率",
    prepRate: "準備率",
    enterPrepRate: "在此處輸入準備率",
    rmbRate: "RMB kg 速度",
    enterRmbRate: "進入 RMB kg 速度",
    skudropMargin: "SKUdrop 利潤",
    enterSkudropMargin: "輸入 SKUdrop 保證金",
    surchargeForCategories: "特定类别附加费",
    enterSurchargeHere: "在这里输入附加费",
    minimum_cbm: "最低限度 CBM",
    miminum_weight: "最小紙箱重量",
    enter_miminum_cbm: "輸入最小值 CBM",
    enter_miminum_weight: "輸入最小重量",
    rmbConversionRate: "RMB/USD 兌換率",
    enterRmbConversionRate: "在此處輸入 RMB/USD 轉化率",
    rmbKgRate: "SKUdrop RMB 公斤率",
    enterRmbKgRate: "在此處輸入 RMB 公斤費",
    over266Rate: "266cm以上率",
    enterOver266Rate: "在此輸入 RMB 中超過 266 厘米的費率",
    CartonRate22_40: "22kg - 40kg 紙箱費率",
    enterCartonRate22_40: "在此輸入 22kg - 40kg 的比率",
    LWH_75_120: "L or W or H = 75cm - 120cm",
    enter_LWH_75_120: "在此處輸入 L/W/H = 75cm - 120cm 比率",
    LWH_243: "L or W or H > 243cm",
    enter_LWH_243: "在此處輸入 L/W/H > 243cm 比率",
    between_330_419: "330cm - 419cm之間",
    enter_between_330_419: "在此處輸入 330 厘米 - 419 厘米之間的比率",
    slowBoat: "慢船",
    mediumBoat: "中型船",
    fastBoat: "快艇",
    savesCharges: "節省費用",
    ratesFileUpload: "上传美国和英国定价的费率文件",
    mainRates: "主要费率",
    dailyStorageRatePerCBM: "每立方米日存储率",
    enterDailyStorageRatePerCBM: "输入每立方米每日存储率",
    skudropAdditionalRates: "SKUdrop 额外费率",
    ownFFPrepRateOver50CBM: "自有货运代理超过 50 CBM 的准备费率",
    enterOwnFFPrepRateOver50CBM: "输入超过 50 CBM 的自有货运代理的准备费率",
    // Customer rates
    customerRates: "客户价格",
    selectCustomer: "选择客户",
    customersLower: "顾客",
    defaultRates: "违约率",
    customRates: "定制费率",
    marginForShipping: "运费保证金",
    prepFeeDiscountLower: "准备费折扣",
    dailyStorageCBMRate: "日存煤层气率",
    saveCustomRates: "保存自定义价格",
    confirmCustomerRates: "确认客户价格",
    ratesType: "费率类型",
    reviewNewRatesAndConfirm: "查看新客户费率并确认更改",
    addShippingCredits: "添加运输信用额",
    addCredits: "添加制作人员",
    consolidationChangeConfirmation: "此更改将影响平台的功能。你确定你要继续吗？",
    activateOver141CBM: "当客户拥有超过 141 CBM 时，这些费率将激活",
    monthlySubscription: "每月订阅",
    monthlySubscriptionIsActivated: "免费包月订阅将被激活",
    monthlySubscriptionIsDisabled: "免费包月订阅将被取消",
    disableMonthlySubscription: "禁用按月订阅",
    enabled: "已启用",
    disabled: "残疾",
    activateGlobalSKUs: "激活全球 SKU",
    shipmentsConsolidationControl: "货运整合控制",
    disableCamelCase: "禁用",
    enableCamelCase: "启用",
    // Payment Terms
    paymentTerms: "付款条件",
    paymentWindow: "付款窗口",
    saleOnTimeDiscount: "1% 准时折扣",
    noOfDaysToPay: "付款天数",
    NoOfCalendarMonths: "日历月数",
    // Customer tiers
    customerTiers: "客户层级",
    tierLevel: "等级 {{value}}",
    defaultTiers: "默认层级",
    customTiers: "自定义层",
    tierStorageCost: "层存储成本",
    tierPrepCost: "层级准备成本",
    saveCustomTierStorageRates: "保存自定义层存储费率",
    confirmCustomerTiers: "确认客户等级",
    tiersType: "层级类型",
    cbmRange: "立方米范围",
    prepCost: "准备费用",
    storageFee: "仓储费",
    reviewTheNewCustomerTiersAndConfirmTheChanges: "审查新的客户等级并确认变更",
    newTiersWillBeAppliedOnlyAfterSwitchingToCustomTiers: "仅在切换到自定义等级后才会应用新等级",
    // Add new Warehouse
    warehouseSetUp: "倉庫設置",
    warehouseTooltip: "用戶名允許您為您的帳戶的SKUDrop 鏈接個性化 。",
    warehouseID: "倉庫編號",
    SKUDropUsername: "SKUdrop 用戶名",
    adress: "地址",
    adressLine1: "地址第一行",
    adressLine2: "地址第二行",
    onChinese: "關於中文",
    province: "省",
    storageSpaceAvailable: "可用存儲空間",
    usableFloor: "可用面積（平方米）",
    palletsCanBeStored: "托盤可以存放",
    saveWarehouseDetails: "保存倉庫詳細信息",
    marketplace_id: "市场 ID",
    // Tenant list
    warehouseList: "倉庫清單",
    allWarehouseList: "所有倉庫",
    totalStorageSqm: "總存儲量",
    palletsStored: "存放的托盤",
    palletSpaceAvailable: "可用托盤空間",
    updateWarehouse: "更新",
    searchForWarehouse: "搜索倉庫 ID...",
    disableWarehouse: "禁用倉庫",
    edit: "編輯",
    visit: "訪問",
    warehouseDetails: "倉庫詳情",
    warehouseSpace: "倉庫空間",
    avaibleStorage: "可用存儲",
    availablePallets: "可用托盤",
    deleteWarehouse: "刪除倉庫",
    userList: "租戶名單",
    totalCartons: "總紙箱数",
    storageCbm: "儲存立方米",
    totalPallets: "托盤總數",
    incomingShipments: "進貨",
    outgoingShipments: "發貨",
    deleteUser: "刪除用戶",
    noProductStored: "我們的系統中沒有存儲任何產品。",
    confirmSave: "確認保存", //added
    //Filter
    thisWeek: "本星期",
    lastWeek: "上個星期",
    thisMonth: "這個月",
    thisYear: "今年",
    roles: "角色",
    role: "角色",
    usersRole: "用戶角色",
    changeRole: "改變角色",
    user: "用戶",
    administrator: "行政人員",
    manager: "經理",
    manageRole: "管理角色", //added
    asignRole: "分配角色", //added
    noUsers: "沒有用戶",
    filterResults: "過濾結果",
    cubicMetersAvailable: "可用立方米",
    shippingService: "送貨服務",
    lastMile: "最後一英里",
    cargoDetails: "貨物詳情",
    termsConditions: "條款和條件",
    fbaWarehouse: "FBA 倉庫",
    emptyProductList: "是時候添加一些產品了！",
    emptyProductListDesc: "在我們幫助您提高供應鏈效率之前，我們需要您的品牌和產品信息。 單擊下面的按鈕開始。",
    addBrandButtonTitle: "添加品牌和產品",
    paymentType: "付款方式",
    valueMustBeNumber: "值必须是数字", //added
    heightValidationErrorMessage: "身高必须小于或等于 419 厘米", //added
    heightRequared: "所需高度", //added
    widthValidationErrorMessage: "宽度必须小于或等于 419 厘米", //added
    widthRequared: "所需宽度", //added
    lengthValidationErrorMessage: "长度必须小于或等于 419 厘米", //added
    lengthRequared: "l所需宽度", //added
    weightValidation: "重量必须小于或等于 68 公斤或 149.91 磅", //added
    fieldRequired: "此字段是必需的",
    minMessage: "數字必須更大",
    maxMessage: "數字必須更大",
    cartonsInPrep: "準備中的紙箱",
    cartonsInTransit: "可用立方米",
    beforeAddTitle: "從亞馬遜進口產品",
    importAllSkuFromSC: "從賣家平台導入所有 SKU",
    importAllSku: "導入所有 SKU",
    enterSpecificSku: "輸入要從賣家平台導入的特定 SKU",
    importSku: "導入 SKU",
    addIntegration: "添加集成",
    addIntegrationDesc: "您的帳戶必須具有有效的亞馬遜集成。",
    integration: "一體化",
    productsImporting: "產品進口...",
    updateExistingProduct: "更新現有產品",
    updateProducts: "更新產品",
    // Shipping declaration
    shippingDeclarations: "運輸聲明",
    shippingDeclarationsDesc: "我們無法運送包含以下內容的產品：",
    powder: "粉末",
    liquids: "液體/油",
    externalProducts: "帶外接電池的產品",
    shippingDeclarationsSecondary: "如果以下 SKU 包含上述任何材料，請將其從以下導入列表中刪除。",
    remove: "消除",
    backUpper: "后退",
    explosives: "炸药",
    flammableGases: "易燃气体",
    flammableLiquids: "易燃液体/液体",
    flammableProducts: "易燃品",
    toxicSubstances: "有毒物质",
    corrosiveProducts: "腐蚀性产品",
    counterfeitGoods: "仿冒品",
    currencies: "货币",
    medicalEquipment: "医用器材",
    food: "食物",
    doesSkusFall: "您的 SKU 是否属于以下类别？",
    skusContainAnyOfTheAbove: "如果以下 SKU 属于上述类别之一，请为每个 SKU 选择适当的类别。",
    notApplicable: "不适用",
    weRequireTheFollowingDocuments: "为了运送此产品，我们需要文件。 对于此类别，我们需要以下文件：",
    manufacturerOrPrivateLabelerName: "制造商或私人标签商名称",
    locationAndDateOfProduction: "产品的生产地点和日期",
    detailedInformationOfTheManufactoring: "制造过程的详细信息，例如批次或运行编号，或其他识别特征",
    informationToFacilitateAscertaining: "有助于确定产品具体来源的任何其他信息",
    pleaseUploadTheseDocumentsByPressing: "请按上传上传这些文件",
    category: "类别",
    exportingChineseCommodity: "中国商检证书",
    fumigationCertificates: "熏蒸证书",
    packingRequirements: "跟踪标签的包装要求",
    labelRequirements: "标签要求",
    cpcDocumentation: "中共文件",
    epaDocumentation: "环保局文件",
    tscaDocumentation: "TSCA 文档",
    siticDocumentation: "SICIT交通安全报告",
    msdsDocumentation: "材料安全数据表文件",
    ukcaDocumentation: "UKCA 文档",
    reachDocumentation: "REACH 文档",
    preimportFiling: "进口前备案",
    qualifiedDocumentation: "合格的文件",
    weeeDocumentation: "WEEE 文档",
    rawBambooAndWooden: "原竹或木制品",
    strongMagneticProducts: "强磁产品",
    pensAndToysChildrenProducts: "笔和玩具/儿童产品",
    powders: "粉末",
    productsWithInternalBatteries: "带内置电池的产品",
    childrensProducts: "儿童用品/玩具",
    cosmetics: "化妆品",
    electronicProducts: "家用电器/电子产品",
    radioactiveProducts: "放射性产品",
    woodenProducts: "木制品",
    nonElectricToys: "非电动玩具",
    electricToys: "电动玩具",
    reportIsJustForTheBatteries: "报告仅针对电池",
    productSurcharge: "产品附加费+3RMB/KG",
    dontHaveDocument: "我没有 CPC 文件",
    confirmSkusDoNotContain: "确认 SKU 不属于上述类别",
    uploadAllDocuments: "请上传所有必需的文件",
    selectSubcategory: "请选择子类别",
    en71standard: "EN71标准",
    en71standardAndIECStandard: "EN71标准和EN IEC 62115:2020标准",
    ceMarkUkcaMark: "CE 标志（欧盟）/ UKCA 标志（英国）",
    productNameModelAndBrand: "产品名称、型号和品牌、生产序列号",
    informationOfTheManufacturer: "制造商名称、地址和联系方式",
    informationOfTheImporter: "进口商的名称、地址和联系方式",
    ukRepresentativeInformation: "欧盟代理人/英国代理人的姓名、地址和联系信息",
    technicalParametersOfTheProduct: "产品技术参数（电压、电流、功率等）",
    toyWarningSlogansAndSigns: "玩具警告标语和标志",
    madeInChinaMark: "MADE IN CHINA 原产地标记",
    radioactiveProductsDeliverOnly: "我们只提供该类别中的以下产品：蓝光、激光打印机、激光雕刻机、激光切割机",
    manufacturersNameAndAddress: "制造商名称和地址",
    nameAndAddressOfResponsiblePerson: "欧盟和英国负责人的姓名和地址",
    countryOfOrigin: "原产地",
    weightOrVolume: "重量或体积",
    cosmeticsUsagePeriod: "化妆品使用期限",
    anyPrecautionsForUse: "任何使用注意事项（保持产品耐久性的预防措施、使用产品时的不良反应等）",
    identificationNumber: "识别号（如批号）",
    functionsAndEffectsOfCosmetics: "化妆品的功效与作用",
    cosmeticIngredients: "成分 - 这些只能在包装上提供。 注意纳米材料的成分后面必须加“(nano)”",
    otherInformationForCosmeticProducts: "其他信息（如防腐剂和紫外线过滤剂，必须提供附加信息）",
    logsNeedToBeFumigated: "原木需要熏蒸",
    // Import products to SKUdrop
    importProductToSkuDrop: "將產品導入 SKUDROP",
    recived: "已收到",
    availableCartons: "可用紙箱",
    uploadImagesButtonLabel: "僅當紙箱尺寸不同時才上傳圖片以提醒客戶。",
    uploadImagesButtonTitle: "上傳圖片",
    labels: "標籤",
    incomingShipmnetsDashboardFiles: "文件",
    confirmIncomingShipment: "確認收貨",
    contactName: "聯繫人姓名",
    contactPhone: "聯繫電話",
    confirmIncomingShipmentDetails: "確認以下收貨詳情",
    marksAcknowledge: "主紙箱嘜頭聲明",
    marksAcknowledgeQuestion: "您是否承認您所有的紙箱都必須有嘜頭標籤？",
    supplierContact: "管理交付的供應商聯繫方式",
    skudropWaitingCartons: "SKUDROP 團隊正在等待您的紙箱",
    important: "重要的",
    downloadIncomingShipmentDescription: "打印通過電子郵件發送給您的收貨標籤，並讓您的供應商將此標籤添加到您發送到 SKUdrop 的每個紙箱上。",
    thisHelpReceiveCartons: "這將幫助我們更快地收到您的紙箱",
    downloadLabels: "下載標籤",
    note: "筆記",
    weHaveSendYouEmail: "我們已向您發送一封電子郵件，確認我們的倉庫地址，供您的供應商發送您的紙箱",
    paymentInvoice: "付款發票",
    ukTariffChargeFiles: "英國關稅收費文件",
    file: "文件",
    productDescription: "產品描述",
    shortDescription: "简短的介绍",
    selected: "已選中",
    supplier: "供應商",
    deliveryDetails: "交貨詳情",
    сartons: "紙箱",
    contactNumber: "聯繫電話",
    estDelivery: "美東時間。 送貨",
    deliveryDetailsTitle: "接收您的紙箱的交貨詳情",
    deliveryDetailsDescription: "此信息用於與您的工廠確認您的紙箱何時交付給 SKUDrop。",
    factoryContactName: "工廠聯繫人姓名",
    contactPhoneNumber: "聯繫電話",
    factoryNickname: "工廠暱稱",
    tariffAmount: "關稅金額",
    helpSectionTitle: "幫助部分",
    chooseVideoTitle: "選擇下面的視頻，幫助您了解更多關於 SKUdrop 平台的信息。 或者訪問我們的 YouTube 知識庫。",
    stillNeedHelp: "仍然需要更多幫助嗎？",
    stillNeedHelpDescription: "如果上述視頻或通過實時聊天與我們的團隊聯繫對您沒有幫助，請隨時向團隊發送電子郵件：",
    understandingDashboard: "了解儀表板",
    profileSection: "簡介部分",
    billingSection: "計費部分",
    pastShipments: "過往出貨區",
    sendProductToSkuDropSection: "將產品發送到 SKUdrop",
    creatingOutboundShipments: "创建出库货件",
    addingProductSection: "添加產品或品牌",
    howGetHelp: "如何獲得幫助",
    uploadTariffSheet: "上傳關稅表",
    SKUdropContactPerson: "SKUdrop 聯繫人",
    CompanyPhoneNumber: "公司電話號碼",
    insurance: "保險",
    enterInsurance: "進入保險",
    allDone: "全部完成！",
    sendProductToAmazonModalTitle1: "我們已收到您的運輸信息和 FBA 標籤。",
    sendProductToAmazonModalTitle2: "我們的倉庫工作人員現在將立即去準備您的紙箱。",
    sendProductToAmazonModalTitle3: "為了更新您的跟踪 ID，請不要在賣家中心查看您的運輸計劃。",
    sendProductToAmazonModalTitle4: "如果您這樣做，我們的系統將無法自動更新您的跟踪 ID。",
    sendProductToAmazonModalTitle5: "知道了，不要再顯示了",
    weightMustBe: "重量必須是",
    kgOrLes: "公斤或更少",
    remote_warehouse_surcharge: "偏遠附加費",
    created: "已創建",
    company: "公司",
    bestRate: "最優惠價格",
    partialDelivery: "部分交貨",
    remaining: "其餘的",
    shipFromAddress: "從地址發貨",
    skudropWarehouseList: "SKUdrop 倉庫清單",
    add: "添加",
    addAddress: "添加地址",
    truck_weight_gte_30: "卡車重量 30kg - 40kg",
    truck_kg_longest_size_120_150: "卡車 KG 120cm-150cm",
    truck_kg_longest_size_150_200: "卡車 KG 150cm-200cm",
    truck_cbm_longest_size_120_150: "卡車 CBM 120cm-150cm",
    truck_cbm_longest_size_150_200: "卡車 CBM 150cm-200cm",
    enter: "進入 ",
    remoteWareouseFile: "遠程倉庫",
    truckInfoFile: "卡車信息",
    minValue: "最小值",
    allTenants: "所有租戶",
    ShipmentsWithPaymentIssue: "有付款問題的貨件",
    deleteAccount: "刪除或禁用帳戶",
    activateAccount: "激活帳戶",
    disableAccount: "禁用賬戶",
    addingSupplier: "添加供应商",
    editFactoryDetails: "编辑工厂详细信息",
    ifYouHaveChangedSuppliers: "如果您更换了供应商，或需要使用系统中现有的供应商详细信息，请选择下面的添加新工厂详细信息。",
    addNewFactoryDetails: "添加新工厂详细信息",
    ifYouNeedToUpdateYourCurrentSuppliers: "如果您需要更新当前的供应商详细信息，请选择下面的更新供应商详细信息。",
    updateSupplierDetails: "更新供应商详细信息",
    alreadyHaveSupplierSaved: "您已经在我们的系统中保存了一个供应商。",
    alreadyHaveSuppliersSaved: "您已经在我们的系统中保存了供应商。",
    useThisSupplier: "使用该供应商",
    ifThisProductUsesDifferentSupplier: "或者，如果该产品使用不同的供应商，请单击“添加新供应商”。",
    addNewSupplier: "添加新供应商",
    confirmSupplierDetails: "确认供应商详细信息",
    // FF Portal
    shippingDashboard: "航運儀表板",
    invoice: "發票",
    packingList: "打包清單",
    detail: "細節",
    taxRule: "稅收規則",
    unlock: "開鎖",
    lock: "鎖",
    details: "更多細節",
    productNameUpper: "產品名稱",
    volume: "體積",
    shipMethod: "發貨方式",
    picture: "圖片",
    shipper: "托運人",
    shipperAddress: "發貨人地址",
    importer: "進口商",
    importerAddress: "進口商地址",
    consignee: "收貨人",
    consigneeAddress: "收貨人地址",
    containerList: "集裝箱清單",
    regular: "常規的",
    express: "表達",
    downloadPackingList: "下載裝箱單",
    searchContainers: "搜索容器",
    sortBy: "排序方式",
    oldestToNewest: "從最舊到最新",
    newestToOldest: "從最新到最舊",
    shipped: "已發貨",
    notShipped: "未發貨",
    all: "全部",
    clearFilters: "清除過濾器",
    saveContainer: "保存容器",
    cancelUpper: "取消",
    addContainer: "添加容器",
    carrier: "載體",
    containerNumber: "集裝箱號",
    createdAt: "創建於",
    space: "空間",
    totalCarton: "總紙箱",
    totalVolume: "總容積",
    totalWeight: "總重量",
    lockUpper: "鎖",
    noContainers: "沒有容器",
    productListUpper: "產品列表",
    assignToContainer: "分配給容器",
    noAvailableContainers: "您沒有可用的容器",
    noSpaceInContainer: "此容器中沒有足夠的空間",
    selectUpper: "選擇",
    noProducts: "沒有產品。",
    description: "描述",
    material: "材料",
    usedFor: "用於",
    quantity: "數量",
    unitValue: "單位價值",
    value: "價值",
    createCommercialInvoice: "創建商業發票",
    noInvoices: "沒有發票。",
    searchByContainerId: "按集装箱号、FBA ID、SKOS 或 SKOG 搜索",
    allShipmentsUpper: "所有貨物",
    noDetails: "沒有細節。",
    customerDetails: "顧客信息",
    customer: "顧客",
    shipment: "運輸",
    order: "命令",
    vendorName: "供應商名稱",
    warehouse: "倉庫",
    carrierLower: "載體",
    streetAddress: "街道地址",
    zipcode: "郵政編碼",
    skuNumber: "SKU編號",
    productPictures: "產品圖片",
    productMaterial: "產品材質",
    productPurpose: "產品用途",
    cartonDetails: "紙箱細節",
    quantityLower: "數量",
    unitCost: "單位成本",
    totalPrice: "總價",
    otherDetails: "其他詳情",
    totalVolumeLower: "總容積",
    totalShippingCost: "總運費",
    netWeight: "淨重",
    downloadFile: "下載文件",
    tariff: "關稅",
    additionalTariff: "附加關稅",
    merchandiseProcessingFee: "商品加工費",
    harbourMaintenance: "港口維護",
    actionUpper: "行動",
    noTaxRule: "沒有稅收規定。",
    hsCodeUpper: "海關編碼",
    deleteContainer: "刪除容器",
    editContainer: "編輯容器",
    downloadPackingListTitle: "下載裝箱單",
    preparingFiles: "正在準備文件...",
    createCommercialInvoiceTitle: "創建商業發票",
    size: "尺寸",
    users: "用戶",
    freightForwarders: "貨運代理",
    approve: "批准",
    disapprove: "不贊成",
    shipmentId: "運輸 ID",
    productMaterialUpper: "產品材質",
    fbaWarehouseId: "FBA 倉庫 ID",
    invalidDateFormat: "請以正確的格式輸入日期 YYYY-MM-DD",
    warehouseUpper: "倉庫",
    selectPeriodUpper: "選擇期間",
    selectFilterPeriod: "選擇過濾週期",
    saveFilterPeriod: "保存過濾週期",
    fromUpper: "從",
    toUpper: "至",
    multipleHsCode: "具有多個 HS 代碼的產品",
    filterByContainer: "按容器篩選",
    searchByFbaIdOrSkos: "按 FBA ID、SKOS 或 SKOG 搜索",
    containerUpper: "容器",
    containerId: "集装箱编号",
    containerNumberFull: "集装箱号",
    awbNumber: "运单号码",
    trackingId: "跟踪号码",
    setContainerId: "设置容器ID",
    setAwbNumber: "设置运单号码",
    setTrackingId: "设置追踪ID",
    trackingForMultipleShipments: "追踪多批货件",
    type: "类型",
    forNextShipments: "以便下次发货",
    here: "这里",
    uploadContainerFile: "上传容器文件",
    containerFileUploading: "容器文件上传",
    followStepsBelowToUploadContainerFile: "请按照以下步骤上传容器文件",
    fillTemplateWithNecessaryData: "使用必要的数据填充模板",
    selectCompletedTemplateByPressingButtonBelow: "按下面的按钮选择已完成的模板",
    afterSelectingFileUploadTemplate: "选择文件后，上传您的模板",
    updateExistingContainer: "更新现有容器",
    selectFile: "选择文件",
    uploadFile: "上传文件",
    filteredBy: "过滤依据",
    group: "团体",
    taxRuleMaxFifeDigits: "逗号后最多只能有 5 位数字",
    // News section
    userCommunication: "用戶溝通",
    skudropCustomerCommunication: "SKUdrop 客戶溝通",
    chooseRecipients: "選擇收件人:",
    selectTenants: "選擇租戶",
    selectAllTenants: "全選:",
    tenants: "租戶",
    banner: "橫幅",
    typeContentHere: "在此處輸入內容...",
    notification: "通知",
    emailNotification: "電子郵件",
    send: "發送",
    selectPeriod: "選擇時期",
    from: "從:",
    to: "至:",
    selectBannerPeriod: "選擇旗幟時期",
    selectTime: "選擇時間",
    savePeriod: "保存期",
    chooseDate: "選擇日期",
    // Affiliate
    affiliate: "會員",
    newAffiliate: "創建新會員",
    saveAffiliateDetails: "保存會員詳細信息",
    communityName: "社區名字",
    codeName: "代碼名稱",
    codeAtributes: "代碼屬性",
    community: "社區",
    code: "代碼",
    codeUsage: "代碼使用",
    TotalCBM: "總煤層氣",
    CurrentCBM: "當前煤層氣",
    accountsDetails: "賬戶詳情",
    CurrentMonthCBMReceived: "當月收到的 CBM",
    AmountToPayTheAffiliate: "支付附屬公司的金額",
    savePaymentsDetails: "保存付款明細",
    payAffiliate: "支付會員",
    affiliateCode: "會員代碼",
    paid: "有薪酬的",
    unPaid: "未付",
    freeSubscriptionPer: "免費訂閱到期",
    prepFeeDiscount: "準備費折扣",
    prepFeeDiscountExpiry: "準備費折扣到期",
    activeCodes: "活動代碼",
    affiliateProgram: "會員計劃",
    paypalEmail: "用於接收付款的 PayPal 電子郵件",
    maxUnits: "每箱最大單位是",
    calculateTariff: "計算關稅",
    storage: "貯存",
    membershipFee: "會員費",
    shipping: "船運",
    tariffsAndTaxes: "關稅和稅收",
    prepFee: "準備費",
    sortedBy: "排序會",
    searchByIds: "按订单 ID 或 FBA ID 搜索",
    filterBy: "過濾",
    searchByFBAId: "按 FBA 编号搜索",
    downloadData: "下载数据",
    downloadEntireList: "下载整个列表",
    zeroCbmTenantsOnly: "只有零个 CBM 租户",
    // Settings
    shippingLabels: "KYT 運輸標籤",
    deleteAcc: "刪除帳戶",
    helpSection: "幫助部分",
    settings: "設置",
    knowledgeBase: "知識",
    allShipments: "所有貨件",
    search: "搜索...",
    allIncoming: "所有傳入",
    allOutgoing: "所有傳出",
    uploadFileToSeeRates: "請上傳費率文件以查看關稅",
    weightMustBeLessThan: "重量必須小於",
    filter: "篩選",
    allLowercase: "全部",
    searchTitle: "搜索",
    incoming: "傳入",
    outgoing: "傳出",
    typeInYourSearch: "在上面輸入您的搜索",
    freightForwardersUnapproved: "未經批准的貨運代理",
    numOfcartons: "NO. 紙箱數量",
    dateRecieved: "接收日期",
    shippingCost: "運輸費",
    tariffCost: "關稅成本",
    tenant: "租戶",
    searchByDate: "按日期搜索",
    filterTariff: "過濾關稅",
    seaShipments: "海運",
    airShipments: "空運",
    displayAirShipments: "顯示空運",
    totalTariffAmount: "關稅總額",
    searchByHsCode: "按 HS 代碼搜索",
    communityOwnerRevenuePerCBM: "每立方米社区所有者收入",
    cmbFreeSubscriptionPeriod: "社区会员福利-免费订阅期",
    cmbPrepFeeDiscount: "社区成员福利-准备费折扣",
    cmbPrepFeeDiscountTimePeriod: "社区会员福利-准备费折扣时间段",
    numberOfShippingCreditsUsage: "运费积分使用次数",
    shippingCreditsAmount: "运费积分金额",
    editAffiliate: "编辑联营公司",
    useShippingCredits: "使用运费积分",
    shippingCredits: "运费积分",
    signUps: "注册",
    balance: "平衡",
    creditUsage: "信用使用情况",
    shippingCreditBalance: "航运信用余额",
    availableBalanceCredits: "您余额中的可用积分",
    creditsWillBeUnavailable: "更改会员代码后，积分将不可用。 你确定你要继续吗？",
    uses: "用途",
    typeOrderNumberOrTenantNameHere: "在此处输入 SKU、SKOG、订单、FBA、发票 ID 或租户名称",
    addMultipleValuesSeparatedByComma: "添加多个值，以逗号分隔",
    affiliateCodeCapitalize: "会员代码",
    tenantName: "租户名称",
    inbound: "入境",
    outbound: "出境",
    CBMStored: "CMB 已存储",
    totalRevenue: "总收入",
    totalRevenueGeneratedFromCode: "代码产生的总收入",
    costToAcquireOneTenant: "获得一名租户的成本",
    ROI: "投资回报率",
    roiPerTenant: "每个租户的投资回报率",
    totalInvestment: "总投资额",
    enterTotalInvestmentHere: "在此输入总投资...",
    data: "数据",
    addCode: "添加代码",
    thisFieldCannotBeEmpty: "该字段不能为空",
    onlyIntegersAreAllowed: "只允许使用整数",
    signupExpirationPeriod: "注册期限及代码",
    manageAffiliatesUpper: "管理附属公司",
    tenantDomainIsNotAvailable: "租户域不可用",
    thereAreNoTenantsToDisplay: "没有要显示的租户",
    // Statistics area
    statistics: "統計數據",
    charts: "圖表",
    growth: "生長",
    newUsers: "新用戶",
    activeUsers: "活躍用戶",
    totalSubscribers: "訂閱者總數",
    averageStorageUsers: "平均存儲/用戶",
    averageShipmentCBMUsers: "平均出貨 CBM/用戶",
    shipments: "出貨量",
    inboundShipments: "入境貨件",
    totalInboundCBM: "入境總計 CBM",
    outboundShipments: "出境貨件",
    outboundCBM: "出站 CBM",
    outboundCartons: "出境紙箱",
    sellers: "賣家",
    smallSellers: "小賣家 0-10 CBM",
    mediumSellers: "中型賣家 11-68 CBM",
    largeSellers: "大賣家 69+ CBM",
    enterpriseSellers: "企業賣家 100+ CBM",
    importantNote: "重要的提示",
    filterByFcId: "按 FC ID 過濾",
    date: "日期",
    requiresConfirmation: "需要確認",
    productUse: "產品用途",
    supplierId: "供應商編號",
    uk_additional_fee_for_weight: "英国重量附加费",
    uk_kg_min_weight: "英国纸箱最小重量",
    uk_moq_min_weight: "英国起订量最小重量",
    uk_mid_size_gte_70_extra: "英国中型 Gte 70 Extra",
    uk_size_gte_100_extra: "英国尺寸 Gte 100 Extra",
    uk_sizes_formula_extra: "英国尺寸公式额外",
    noProductsFromAmazon: "沒有產品。 請從亞馬遜進口產品",
    supplierIdUpper: "供應商編號",
    growsCharts: "增長圖表",
    shipmentCharts: "出貨量圖表",
    downloadReport: "下載報告",
    paymentIssues: "付款問題",
    pay: "支付",
    etdOldestToNewest: "ETD 從最舊到最新",
    etdNewestToOldest: "ETD 從最新到最舊",
    etaOldestToNewest: "ETA 從最舊到最新",
    etaNewestToOldest: "ETA 從最新到最舊",
    amazonLink: "亞馬遜鏈接",
    reporting: "報告",
    selectReportingPeriod: "選擇報告期",
    searchForCountryCode: "按國家代碼搜索",
    allAddress: "所有地址",
    uploadDocument: "上傳文件",
    uKShippingPreparation: "UK 運輸準備",
    vatCertificateUpload: "VAT 證書上傳",
    noteDocusignUk: "注意：不接受使用 HRMC 地址的證書",
    ukVatNumber: "英國增值稅號",
    eoriNumber: "EORI 號碼",
    vatRegistrarCompany: "增值稅登記公司",
    vatRegistrarLegalRepresentative: "增值稅登記官法定代表",
    customClearanceAuthorisation: "海關清關授權和進口PVAT授權",
    readAndSign: "閱讀並簽名",
    cogsReporting: "销货成本报告",
    vatBusinessName: "公司名称",
    // Past shipments
    ctns: "纸箱",
    cbmUpper: "立方米",
    noShippmentsToSkudrop: "沒有要發送到 SKUdrop 的產品。",
    UKApprovals: "英國認證",
    vatCertificate: "VAT 證書",
    vatRegistrarCompanyDetails: "VAT 註冊商公司詳情",
    customsClearanceAuthorisation: "清關授權",
    authorisationOfImportPvat: "進口 PVAT 授權",
    thereNoDocumentsToApprove: "沒有要批准的文件。",
    docusignUkRequireDocumnets: "我們需要某些文件和信息才能代表您運送到英國。請提供以下信息。",
    youCantSendProductsToSkudrop: "在您的英国货运单据获得批准之前，您无法将纸箱发送至 SKUdrop。",
    // Integrations modal
    connectingSkudropTo: "将 SKUdrop 连接到卖家平台",
    connectingYourSellerCentral: "将您的卖家中心帐户连接到 SKUdrop 将帮助您在我们的系统内创建运输计划，以节省您的时间。",
    pleaseChooseTheMarketplace: "请选择您要连接的市场。",
    onceYouImportYourProducts: "从市场导入产品后，您可以稍后从其他市场导入产品。",
    // HS validation modal
    HSValidation: "HS编码验证",
    useCorrectHSCodeformat: "请使用正确的 HS 代码格式，即 0000.00.00.00",
    VerifyingHS: "正在检查 HS 代码...",
    HSIsVerified: "您的 HS 代码已验证，请按“确认”继续",
    detectProblemsWithHS: "该 HS 代码无效，请输入有效的 HS 代码",
    enterHSInFieldAbove: "在上面的字段中输入 HS 代码，然后按“检查”",
    checkUpper: "查看",
    generetingDocusignFile: "我們正在生成您的文件。您很快就會被重定向。",
    ukSeaShipments: "UK 海運",
    ukTruckShipments: "UK 卡車運輸",
    statusUpper: "地位",
    cantFindHs: "找不到您的 HS 代码？",
    clickHereUpper: "点击这里",
    // HS info modal
    usReason: "原因是 HS 代码对于美国 2023 年 HTS 清单无效。 我们的系统会与美国 HTS 官方网站检查代码，以确保它们当前有效。",
    usWePrevent: "我们这样做是为了防止在运输您的产品时使用任何无效代码，从而可能导致货物被美国海关扣留。",
    usHsInfoLink: "您可以向您的供应商询问新的有效 2023 年美国 HS 代码，或者您可以自行搜索新的 HS 代码 ",
    usYoutubeVideoLink: "以下是有关 HS 编码的有用视频的链接：",
    usRecommendProfessional1: "另一种选择是让专业人士帮助找到正确的代码，我们推荐 John Burhans，网址为 ",
    usRecommendProfessional2: "他们为 SKUdrop 客户提供折扣。",
    ukReason: "出现此错误的原因是您输入的 HS 代码不是有效的英国关税代码。",
    ukWePrevent: "我们的系统会通过英国关税官方网站检查代码，以确保其正确。 这样做是为了防止在运输产品时使用无效代码，从而可能导致货物被英国海关扣留。",
    ukHsInfoList: "您可以向您的供应商询问新的有效英国关税代码，或者您可以自行搜索新的 HS 代码 ",
    // Bulk SKU upload modal
    uploadSkusInBulk: "批量上传SKU",
    bulkSkuUploading: "批量SKU上传",
    followStepsToUploadDetailsBulk: "请按照以下步骤批量上传 SKU 详细信息。",
    step: "步",
    downloadBulkSkuUploadTemplate: "下载批量 SKU 上传模板。",
    fillInTemplateWithDetails: "在模板中填写您的 SKU 详细信息。",
    ensureYouSelectCorrectMeasurement: "请确保您选择正确的测量类型。",
    uploadCompletedTemplateByPressing: "按下面的上传按钮上传完成的模板。",
    downloadTemplate: "下載模板",
    uploadTemplate: "上传模板",
    pleaseUploadValidExcelFile: "请上传有效的 Excel 文件。",
    // Switch Tab Modal
    warning: "警告",
    continue: "继续",
    createShippingPlansForOneMarket: "一次为一个市场制定运输计划。",
    enteredDataWillBeRemoved: "转到新选项卡时，输入的数据将被删除。",
    // Track shipments
    shipmentTracker: "追踪發貨",
    yourLiveShipments: "您的實時發貨情況",
    latestShipped: "最新發貨",
    nextToArrive: "接下來到達",
    noShipmentsToTrack: "没有可追踪的货件",
    enterSkuHereToTrack: "搜索 SKU：输入 SKU，然后按 Enter...",
    noSKUsMatchThisSearch: "没有与此搜索匹配的 SKU。",
    // Tenant manual quoting
    liveQuotes: "现场报价",
    requestAQuote: "索取报价",
    thereAreNoLiveQuotes: "没有实时报价",
    accept: "接受",
    reject: "拒绝",
    quote: "报价",
    prepFeesOf: "+ 准备费",
    pending: "待定",
    labelRequiredForPrepNo: "预处理时需要标签 - 无",
    destinationFC: "目的地 FC",
    quoteConfirmation: "报价确认",
    toAmazon: "致亚马逊",
    seaShipping: "海运",
    airShipping: "空运",
    medium: "中型",
    serviceLoverCase: "服务",
    cartonsLoverCase: "纸箱",
    cartonLoverCase: "纸箱",
    acceptShipmentQuote: "接受装运报价",
    rejectQuote: "拒绝报价",
    requestFreightQuote: "申请货运报价",
    seaUpperCase: "海",
    airUpperCase: "空气",
    stepSelectDestination: "步骤 2 - 选择目的地",
    selectMarketPlace: "选择市场",
    enterFbaFC: "进入 FBA FC",
    enterUpperCase: "输入",
    stepAddDestination: "步骤 2 - 添加目的地",
    addressLine1: "地址 1",
    zipPostalCode: "邮政编码",
    submitShipmentForQuote: "提交装运报价",
    selectFCForSKU: "为 SKU 选择 FC",
    empty: "空",
    addFC: "添加 FC",
    toCamelCase: "到",
    fbaReferenceNo: "FBA 参考号",
    unitsSlashCtn: "单位/纸箱",
    receivingAddressHasLoadingDock: "收货地址是否有装卸区？",
    bulkSkuUpload: "批量SKU上传",
    followStepsBelowToBulkUploadSKUsForYourShippingQuote: "请按照以下步骤批量上传 SKU，以便进行发货报价。",
    downloadBulkUploadTemplate: "下载批量上传模板。",
    completeTemplateWithDetailsOfYourSKUsForWhichYouLikeShippingQuote: "在模板中填写您希望获得运费报价的 SKU 的详细信息。",
    // Quote Orders
    quoteOrders: "报价订单",
    pendingQuoteOrders: "待定报价订单",
    quoteFile: "报价文件",
    quotes: "引号",
    allQuotingOrders: "所有报价订单",
    finalQuote: "最终报价",
    confirmQuote: "确认最终报价",
    confirmQuoteOrder: "确认报价订单",
    quoteUpdating: "报价更新",
    updateQuoteOrder: "更新报价单",
    noQuoteRequests: "没有报价请求",
    noPendingQuoteRequests: "没有待处理的报价请求",
    destinationCountry: "目的地国家",
    quoteRequests: "报价请求",
    quoteRequestsWithPaymentIssues: "付款问题的报价请求",
    pendingQuoteRequests: "待处理的报价请求",
    pendingQuoteRequestsWithPaymentIssues: "待处理的付款问题的报价请求",
    quoteRequestsCamelCase: "报价请求",
    activateManualQuoting: "激活手动报价",
    // Operations message
    totalSizeOfImagesShouldNotExceed: "图片总大小不应超过",
    pleaseUploadFileSmallerThan: "请上传小于 {{size}}",
    imageCompressionInProgress: "正在进行图像压缩。 请稍候",
    somethingWentWrongWhileCompressingTheImages: "压缩图像时出现问题",
    somethingWentWrongDuringReceivingCartons: "接收纸箱时出现问题",
    pleaseUploadRatesFile: "请上传费率文件",
    pleaseSelectShippingService: "请选择运输服务",
    pleaseSelectContainerSize: "请选择容器大小",
    // Warning messages
    youCanSelectUpToOrdersAtATime: "您每次最多可选择 {{count}} 个订单",
    // Validation messages
    pleaseEnterValidFbaID: "请输入有效的亚马逊物流 ID",
    endCantBeInPast: "結束日期不能是過去",
    timeFieldRequired: "時間字段是必需的",
    timeEndMustBeLater: "時間結束必須晚於開始",
    pleaseChooseRecipients: "請選擇收件人列表或按全選",
    pleaseEnterOneField: "請輸入三個字段之一",
    theValueIsIncorrect: "该值不正确",
    maximum90Characters: "最多 90 個字符",
    maximum120Characters: "最多 120 個字符",
    dateIsRequired: "日期为必填项",
    timeIsRequired: "需要时间",
    onlyANumberIsAllowed: "只允许使用数字",
    atLeastOneTrackingIdIsRequired: "至少需要一个跟踪 ID",
    onlyPdfFilesAreAllowed: "只允许使用 PDF 文件",
    fbaFCAlreadyAdded: "已添加 FBA FC",
    invalidFbaFC: "无效的 FBA FC",
    trackingIdsThatHaveSameValue: "所有具有相同值的跟踪 ID 必须完全相同",
    pleaseUploadValidPdfFile: "请上传有效的 PDF 文件",
    pleaseUploadValidPdfFiles: "请上传有效的 PDF 文件",
    youDoNotHaveEnoughCartonsForProductAvailableNumberOfCartonsForSkuIs: "您没有足够的产品纸箱。{{sku}} 的可用纸箱数（{{units_per_carton}} 单位/箱）为 {{available_cartons}}。",
    maximumNumberOfCartonsForThisProductIs: "该产品的最大纸箱数为 {{available_cartons}}。",
    // Confirmation messages
    capacityOfDesiredContainerTooSmall: "所需容器的容量太小，输入的纸箱数量将被清除。 你想继续吗？",
};
